import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/yubopics.jpg';


const Hero = () => {

    const [backgroundPosition, setBackgroundPosition] = useState('center');
  
    useEffect(() => {
      const handleResize = () => {
        // Calculate new background position
        // Example: shift the background position left by a percentage based on the window width
        const positionX = 75 + window.innerWidth / 100; // Decreases as screen size decreases
        setBackgroundPosition(`${positionX}% center`);
      };
  
      // Set initial position based on current window size
      handleResize();
  
      // Add event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up event listener
      return () => window.removeEventListener('resize', handleResize);
    }, []);



  return (
    <div
      className="h-screen w-full flex overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: backgroundPosition,
      }}
    >

      <div className="m-auto ">
        <div className="text-center text-white font-bold">
          <p className="text-2xl md:text-3xl italic mb-2">Hey, I am</p>
          <p className="text-7xl md:text-8xl">Yubo Jing</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;