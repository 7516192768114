import React from 'react';
import courseknotGif from '../assets/courseknot.gif';
import matchxplainGif from '../assets/matchxplain.gif';
import unishareGif from '../assets/unishare.gif';
import smartHealthGif from '../assets/smarthealth.gif';
import { styles } from './Styles';

function Project() {
    const projects = [
        {
            title: "CourseKnot",
            gif: courseknotGif,
            description: "CourseKnot is an AI-driven course advisory platform that leverages the ChatGPT API to summarize student feedback, providing detailed course information at a glance. Our comment ranking algorithm highlights relevant, popular, and unique perspectives, helping students make informed decisions about their course selection.",
            tags: ["React.js", "Django", "MySQL", "Figma", "AWS", "GPT4 API"],
            link: "https://github.com/CourseKnot/KnowMyClass-Old/tree/redesign"
        },
        {
            title: "MatchXplain",
            gif: matchxplainGif,
            description: "MatchXplain is a not-for-profit platform with the grand vision of providing explainable AI solutions that involve decisions under (often ill-structured) preference data. Its goal is delivering a suite of provable recommendations to facilitate group decision-making through explainable data, robust matchings, and versatile solutions.",
            tags: ["Python", "React.js", "Redis.js", "DynamoDB", "AWS Amplify", "AWS Lambda", "AWS S3", "AWS EC2"],
            link: "https://www.matchxplain.org"
        },
        {
            title: "Uni.Share",
            gif: unishareGif,
            description: "Uni.Share is a prototype decentralized C2C app for university students, inspired by Steemit. It features a reward system with virtual currencies 'Uni.Corn' and 'Uni.Shares' to encourage information sharing and connection. The app, structured like a forum, covers topics from courses to housing, with a credibility rating system for reliable information.",
            tags: ["Figma", "Firebase", "Flutter", "Dart"],
            link: "https://devpost.com/software/uni-share"
        },
        {
            title: "AI and VR for Smart Health	",
            gif: smartHealthGif,
            description: "AI and VR for Smart Health is a prototype interactive VR game designed to promote knowledge of cardiac myocytes. Using Unreal Engine 4, the game simulates ion movement and action potentials with C++ and Blueprint. It features a user-friendly interface with prompts and tooltips, and monitors user behavior with an HTC Vive Pro 2 Kit to enhance visualization.",
            tags: ["C++", "Unreal Engine 4", "UE4 Blueprint", "HTC Hive Pro", "Blender"],
            link: "https://drive.google.com/file/d/1A3-v41VVE55ttiY_QeDrMcJ9CkamWRB6/view?usp=sharing"
        },
    ];

    return (
        <div style={{ backgroundColor: '#f9f4ef', color: '#272343'}}>
            <div className="max-w-screen-lg mx-auto py-8 flex flex-col items-center justify-center">
                <div className="py-4 w-full flex flex-col items-center">
                    <div className={styles.sectionTitle}>
                        <div className="border-t border-black w-20 mr-3"></div>
                        My Projects
                        <div className="border-t border-black w-20 ml-3"></div>
                    </div>
                </div>

                {projects.map((project, index) => (
                    <div key={index} className="flex flex-col md:flex-row items-center md:items-start w-full mb-12 px-12">
                        <div className="md:w-2/5 flex justify-center">
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <img 
                                    className="rounded-2xl shadow-lg mb-4 md:mb-0" 
                                    src={project.gif} 
                                    alt={`${project.title} Preview`} 
                                />
                            </a>
                        </div>

                        <div className="md:w-2/4 md:pl-8 text-left">
                            <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <h1 className={styles.pageTitle}>{project.title}</h1>
                            </a>
                            <p className="text-gray-700 mb-4">{project.description}</p>
                            <div className="flex flex-wrap justify-start">
                                {project.tags.map((tag, index) => (
                                    <span key={index} className="bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded mb-2">{tag}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Project;
