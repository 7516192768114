import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white text-center p-6 flex flex-col items-center justify-center space-y-4">
      <p className="text-lg font-semibold">
        Driven by Innovation, Fueled with Love ❤️
      </p>
      <p className="text-sm">
        Exploring new horizons at the intersection of technology and creativity.
      </p>
      <div className="flex space-x-4">
        <a href="https://github.com/yuboooo" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
          <i className="fab fa-github fa-2x"></i>
        </a>
        <a href="https://www.linkedin.com/in/yubojing/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
          <i className="fab fa-linkedin fa-2x"></i>
        </a>
      </div>
      <p className="text-xs">
        © 2024 Yubo Jing. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
