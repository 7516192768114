import React from 'react';
// import { useEffect, useState } from 'react';
import Hero from './components/Hero';
import Footer from './components/Footer';
import About from './components/About';
import Journey from './components/Journey';
import Project from './components/Project';
// import Views from './components/Views';
import './index.css';

function App() {
  // const [message, setMessage] = useState('');

  // useEffect(() => {
  //   fetch('http://localhost:3001/api/test')
  //     .then(response => response.json())
  //     .then(data => setMessage(data.message))
  //     .catch(err => console.error('Error fetching data: ', err));
  // }, []);

  return (
    <div className="App">
      <Hero />
      <About />
      <Journey />
      <Project />
      {/* <Views /> */}
      <Footer />
      {/* <p>
        Server says: {message}
      </p> */}
    </div>
  );
}

export default App;
