export const styles = {
    sectionTitle: "text-2xl md:text-4xl font-bold flex items-center mb-8", // section title, e.g. "My Projects"
    pageTitle: "text-2xl font-semibold mb-2", // title of the project, e.g. "Uni.Share"
    container: "px-12 flex flex-col md:flex-row items-center md:items-start w-full",
    imageContainer: "md:w-1/4 flex md:justify-center",
    roundedImage: "rounded-2xl shadow-lg mb-4 w-[240px] h-[180px]",
    locationText: "text-gray-500 mb-5",
    bodyText: "text-gray-700 mb-4",
    textContainer: "md:w-2/3 md:pl-8 text-left"
};
