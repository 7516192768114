import React from 'react';
import psu from '../assets/psu.png';
import brown from '../assets/brown.png';
import { styles } from './Styles';

// Journey.js

function Journey() {
    
    return (
        <div style={{ backgroundColor: '#fffffe', color: '#272343'}}>
            <div className="max-w-screen-lg mx-auto py-8 flex flex-col items-center justify-center">
                <div className="py-4 w-full flex flex-col items-center">
                    <div className={styles.sectionTitle}>
                        <div className="border-t border-black w-20 mr-3"></div>
                        My Journeys
                        <div className="border-t border-black w-20 ml-3"></div>
                    </div>
                </div>

                {/* Brown University */}
                <div className={styles.container}>
                    <div className={styles.imageContainer}>
                        <img 
                            className={styles.roundedImage} 
                            src={brown} 
                            alt="Brown University Logo" 
                        />
                    </div>
                    <div className={styles.textContainer}>
                        <h1 className={styles.pageTitle}>Brown University</h1>
                        <p className='text-red-700 mb-2'><strong>M.S. in Computer Science, 2024 - 2026</strong></p>
                        <p className={styles.locationText}><strong>Providence, RI</strong></p>
                        <p className={styles.bodyText}>After a transformative journey at Penn State, I am excited to continue my academic pursuits at Brown University, where I will be studying for a Master of Science in Computer Science. I look forward to immersing myself in Brown's collaborative and innovative environment, where I can explore advanced topics in computer science and participate in meaningful research.</p>
                    </div>
                </div>

                <br></br>
                <br></br>

                {/* PSU */}
                <div className={styles.container}>
                    <div className={styles.imageContainer}>
                        <img 
                            className={styles.roundedImage} 
                            src={psu} 
                            alt="Penn State University Logo" 
                        />
                    </div>
                    <div className={styles.textContainer}>
                        <h1 className={styles.pageTitle}>Pennslyvania State University</h1>
                        <p className='text-blue-500 mb-2'><strong>B.S. in Computer Science & Economics, earned Dec 2023</strong></p>
                        <p className={styles.locationText}><strong>State College, PA</strong></p>
                        <p className={styles.bodyText}>Penn State provided a dynamic environment filled with opportunities that significantly influenced my academic and professional development. The mentorship from outstanding professors and the wealth of resources and extracurricular activities available enriched my understanding and broadened my perspectives.</p>
                        <p className={styles.bodyText}>Creating <strong>CourseKnot</strong> with a dedicated team and participating at the Happy Valley Launchbox was a transformative experience. We continually refined our concept based on student feedback, adapting to meet the needs of students. This journey pushed me to delve into the intricacies of product development and ignited my passion for startups and entrepreneurship.</p>
                        <p className={styles.bodyText}>My research experience with Dr. Hadi Hosseini on the <a href="https://www.matchxplain.org" className="text-blue-500" rel="noreferrer" target="_blank"><strong>MatchXplain </strong></a>project immersed me in the intersection of computer science, economics, and AI. We tackled complex matching problems and decision-making under ill-structured preference data, blending diverse fields to create impactful solutions. This experience expanded my research abilities and fueled my commitment to finding impactful ways to improve people's lives.</p>
                        <p className={styles.bodyText}>Penn State inspired my passions, deepened my love for learning, and enriched my worldview through diverse experiences and challenges.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Journey;
