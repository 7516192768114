import React, { useState, useEffect, useRef } from 'react';
import { styles } from './Styles';

// modify commands below to change the terminal output
const commands = {
  'Yubo.hometown': ['Shenzhen, China'],
  'Yubo.education': ['Penn State University, Brown University'],
  'Yubo.majors': ['Computer Science & Economics'],
  'Yubo.interests': ['["Code💻", "Drum🥁", "Camp⛺️", "Music🎵", "Hike🚶", "Read📖", "Movies🎬"]'],
  'Yubo.agent': ['I\'m currently building a digital Yubo using large language models like GPT-4, coming soon!🔥'],
  'clean': [],
};

const defaultOutput = Object.entries(commands).reduce((acc, [command, response]) => {
  if (command !== 'clean') {
    acc.push({ text: `~$ ${command}`, type: 'command' });
    acc.push({ text: response, type: 'response' });
  }
  return acc;
}, []);

// commands['help'] = ['Available commands: ' + Object.keys(commands).join(', ')];

const About = () => {
  const [input, setInput] = useState(''); 
  const [output, setOutput] = useState(defaultOutput);
  const inputRef = useRef(null); 

  const datalistOptions = Object.keys(commands).map((command) => <option key={command} value={command} />);

  useEffect(() => {
    if (output !== defaultOutput) {
      inputRef.current?.focus();
    }
  }, [output]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && input.trim()) {
      const command = input.trim();
      const newOutput = processCommand(command);
      setOutput(prev => [
        ...prev, 
        { text: `~$ ${command}`, type: 'command' },
        ...newOutput.map((line, index) => ({ text: line, type: 'response', key: index }))
      ]);
      setInput(''); 
    }
  };
  
  const processCommand = (command) => {
    if (commands[command]) {
      if (command === 'clean') {
        setOutput([]); 
      }
      return commands[command];
    } else {
      return ['command not found'];
    }
  };

  return (
    // div background color
    <div className="flex flex-col items-center justify-center py-12 px-10" style={{ backgroundColor: '#f9f4ef', color: '#0d0d0d'}} >
      <div className={styles.sectionTitle}>
        <div className="border-t border-black w-20 mr-3"></div>
          About Me
        <div className="border-t border-black w-20 ml-3"></div>
      </div>

      <div className="bg-gray-100 flex flex-col justify-between rounded-lg overflow-hidden w-full max-w-3xl" style={{ height: '600px' }}>
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between px-4 py-2 bg-gray-200 rounded-t-lg">
            <div className="flex items-center space-x-2">
              <div className="w-3 h-3 bg-red-500 rounded-full"></div>
              <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            </div>
            <div className="flex items-center">
              <div className="text-gray-800 font-bold">Yubo Jing</div>
            </div>
            <div className="text-gray-200 font-mono">Yubo.J</div>
          </div>
          <div className="flex flex-col flex-grow overflow-y-auto px-4 py-2" style={{ backgroundColor: '#242629' }}>
            
            {output.map((item) => (
              <p key={item.key} className={`font-mono ${item.type === 'command' ? 'text-white' : 'pl-6'}`} style={{ color: item.type === 'command' ? '' : '#94a1b2' }}>
              {item.text}
            </p>
            
            ))}
            <div className="flex">
              <p className="text-gray-200 font-mono">~$</p>
              <input
                ref={inputRef}
                type="text"
                list="commands"
                className="bg-transparent outline-none text-gray-200 font-mono w-full ml-2"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <datalist id="commands">
                {datalistOptions}
              </datalist>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
